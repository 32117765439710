import React from "react"
import { Link } from "@StarberryUtils"
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import '../../ProductDetails/SimilarProperties/SimilarProperties.scss';
import { PropertyListByTeam } from "../../../queries/common-use-query";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const PropertiesByTeam = (props) => {
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,

                },
            },
        ],
    }
    const { data, loading, error } = PropertyListByTeam(props.id, 10);
    let processedImages = JSON.stringify({});
    if (data?.property?.imagetransforms?.images_Transforms) {
        processedImages = data.property.imagetransforms.images_Transforms;
    }
    return (
        <>
            {(data && data.properties && data.properties.length > 0) && <section className="similar-properties mt-4">
                <Container>
                    <span class="sub-content"> &nbsp;&nbsp;&nbsp; properties &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <h3>Recent sales from {props.user}</h3>
                        {(data && data.properties) && <Slider className={`slick-arrow ${data.properties.length <= 2 ? "similar-single-properties" : ""}`} {...settings}>
                            {data.properties.map((property, i) => 
                                <div className="slider-wrap" key={i}>
                                    <Link to={`/property-${property.search_type === "sales" ? "for-sale" : "to-rent"}/${property.slug}-${property._id}`}>
                                        <div class="slider position-relative">
                                            <div className="home-img">
                                                {property.images.length > 0 && 
                                                    <ImageTransform
                                                        imagename="property.images.detail"
                                                        attr={{ alt: '', class:'' }}
                                                        imagesources={property.images[0].url}
                                                        renderer="srcSet"
                                                        imagetransformresult={processedImages}
                                                        id={property.id}
                                                    />
                                                }                             
                                                {/* {property.images.length > 0 && <picture>
                                                    <source media="(min-width:1200px)" srcSet={property.images[0].srcUrl.replace("http", "https")} />
                                                    <img src={property.images[0].srcUrl.replace("http", "https")} alt="img" />
                                                </picture>  }                              */}
                                                <div className="home-content backdrop-content props-by-team">
                                                    <h4>{property.display_address}</h4>
                                                    <span className="price">{property.price_qualifier} £{property.price.toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <button className="prop-card-btn">{property.status}</button>
                                        </div>
                                    </Link>
                                </div>
                            )}                       
                            {window.innerWidth > 1024 && <div className="slider-wrap"></div>} 
                        </Slider>}
                    </ScrollAnimation>
                </Container> 
            </section>}
        </>
    )
};
export default PropertiesByTeam