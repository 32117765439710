import * as React from "react"
import { graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/grunticon.scss";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import '../components/Static/AboutPage/About.scss';
import { Col, Container, Row, Modal } from "react-bootstrap";
import HTMLReactParser from "html-react-parser";
import ConsulationForm from "../components/forms/consultation-form";
import ContactUsForm from "../components/forms/contact-us-form";
import PropertyListByTeam from "../components/collections/Teams/PropertiesByTeam"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs_Details";
import SEO from "../components/seo";

const TeamTemplate = (props) => {
    const top_ref = React.useRef();
    const [showForm, setShowForm] = React.useState(false);
    const [showEmailForm, setShowEmailForm] = React.useState(false);
    const [isReadMore, setReadMore] = React.useState(false) // local state for less/expand readmore
    const [email, setEmail] = React.useState("");
    const GQLPage = props.data.glstrapi?.team;
    let processedImages = JSON.stringify({});
    if (GQLPage?.imagetransforms?.images_Transforms) {
        processedImages = GQLPage.imagetransforms.images_Transforms;
    }

    const readLess = () => {
        setReadMore(!isReadMore);
        top_ref.current.scrollIntoView({behavior: 'smooth'});
    }

    const readMore = () => {
        setReadMore(!isReadMore);
    }
    return (
        <div className="wrapper wrapper-static">
            <SEO
                title={`${GQLPage.Name} | ${GQLPage.Designation}`}
                description={`Meet ${GQLPage.Name} specialising as ${GQLPage.Designation} at Bovingdons in ${GQLPage.office[0]?.Title}. An estate agent team built on customer care and passion for the local property market.`}
            />
            <Header/>
            <section className="about">
                <Container>
                    <Row className="justify-content-around our-team-video-details">
                        <Col className="col-12 col-xl-12">
                            <Breadcrumbs className="align-left" location={props.location} pageName = {"Meet Our Team"}/>
                        </Col>
                        <Col className="col-12 col-xl-6">
                            {GQLPage.Full_Image &&
                            <ScrollAnimation animateIn='fadeInRight'>
                                <div className="about-card-img img-zoom px-0">
                                    <ImageTransform
                                        imagename="teams.Full_Image.detail"
                                        attr={{ alt: GQLPage.Full_Image.alternativeText, className: '' }}
                                        imagesources={GQLPage.Full_Image.url}
                                        renderer="srcSet"
                                        imagetransformresult={processedImages}
                                        id={GQLPage._id}
                                    />
                                </div>
                            </ScrollAnimation>
                            }
                        </Col>
                        <Col className="col-12 col-xl-6">
                            <ScrollAnimation animateIn='fadeInLeft'>
                                <div className="about-card-details" ref={top_ref}>
                                    <h1>{GQLPage.Name}</h1>
                                    <p className="profile">{GQLPage.Designation}</p>
                                    {GQLPage.Name !== "Jo Fisher" && <div className="buttons">
                                        <a onClick={() => setShowForm(true)} className="btn btn-primary">
                                            book a consultation
                                        </a>
                                    </div>}
                                    <div className="icons">
                                        <p className="phone-calls">
                                            <i className="icon-phone"></i>
                                            <a href={`tel:${GQLPage.Phone}`} className="tel">{GQLPage.Phone}</a>
                                        </p>
                                        <p className="mail-us">
                                            <i className="icon-mail"></i>
                                            <a href="javascript:;" onClick={() => { setEmail(GQLPage.Email); setShowEmailForm(true); }} 
                                                className="mail">{GQLPage.Email}</a>
                                        </p>
                                    </div>
                                    <div className="team-description-rm">
                                        <div className={`feature-content ${isReadMore ? 'expand': '' }`}>
                                            {HTMLReactParser(GQLPage.About.replace(/\n/g,'<br />'))}
                                        </div>
                                        <div id="offset_top" className="more">
                                            { !isReadMore ? 
                                                <span className="read-more" onClick={readMore}>Read More</span> : 
                                                <span className="read-more" onClick={readLess}>Read Less</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
            <PropertyListByTeam user={GQLPage.Name} email={GQLPage.Email} id={GQLPage.Calendly_Link}/>
            <Footer search="Popular_Search_About"/>
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Book a Consultation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConsulationForm email={GQLPage.Email}/>
                </Modal.Body>
            </Modal>
            <Modal show={showEmailForm} onHide={() => setShowEmailForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={email}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TeamTemplate

export const pageQuery = graphql`
    query teamQuery($id: ID!) {
        glstrapi {
            team(id: $id) {
                Meta_Description
                Email
                Designation
                About
                Meta_Title
                Name
                Phone
                Sort
                Full_Image {
                  url
                  alternativeText
                }
                Calendly_Link
                URL
                _id

                office: our_offices {
                    URL
                    Title
                }
            }
        }
    }
`